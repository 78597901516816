import { Carousel, Col, Container, Image, Row } from "react-bootstrap"
import * as React from "react"
import { BsArrowRight, BsFillSquareFill } from "react-icons/bs"
import { BsChevronDown } from "react-icons/bs"
import green_city from "../../public/static/images/home-banner-bg.jpg"
import who_we_are from "../../public/static/images/who-we-are.png"
import truck_mounted from "../../public/static/images/products/truck_mounted_anti_smog_gun.jpg"
import semi_automatic from "../../public/static/images/products/semi_automatic.jpg"
import auto_collector from "../../public/static/images/products/auto_collector.jpg"
import refuse_compactor from "../../public/static/images/products/refuse_compactor.jpg"
import dumper_placer from "../../public/static/images/products/dumper_placer_cropped.jpg"
import hook_loader from "../../public/static/images/products/hook_loader.jpg"
import portable_compactor from "../../public/static/images/products/portable_compactor.jpg"
import road_n_street_sweeper from "../../public/static/images/products/road_n_street_sweeper.jpg"
import super_sucker from "../../public/static/images/products/super_sucker.jpg"
import suction_cum_jetting from "../../public/static/images/products/suction_cum_jetting.jpg"
import sewer_suction from "../../public/static/images/products/sewer_suction.jpg"
import nala_cleaning from "../../public/static/images/products/nala_cleaning.jpg"
import desilting from "../../public/static/images/products/desilting.jpg"
import sky_lift from "../../public/static/images/products/sky_lift.jpg"
import mobile_toilet from "../../public/static/images/products/mobile_toilet.jpg"
import portable_toilet from "../../public/static/images/products/portable_toilet.jpg"
import multi_seater_toilet from "../../public/static/images/products/multi_seater_toilet.jpg"
import water_tanker from "../../public/static/images/products/water_tanker.jpg"
import fogging_machine from "../../public/static/images/products/fogging_machine_cropped.jpg"
import dustbin from "../../public/static/images/products/dustbin.jpg"

import Layout from "../components/layout"
import "./styles/index.css"
import { Link } from "gatsby"
// import HomeGallery from "../components/home_gallery"

const IndexPage = () => {
  const products = [
    {
      name: "Truck Mounted Anti Smog Gun",
      desc: "Anti-smog gun is a canon shaped device that sprays atomized water droplets in the air. Air atomization enables tiny particles or water, which increases the number or droplets and reduces the surface tension of the water.",
      image: truck_mounted,
      link: "truck-mounted"
    },
    {
      name: "Semi Automatic Anti Smog Gun",
      desc: "Anti-smog gun is a canon shaped device that sprays atomized water droplets in the air. The objective of Anti Smog Gun is to remove dust particles from the air so that quality of air is improved to reach the permissible level of ambient air quality standards. We are the leading manufacturer and supplier of Anti Smog Gun in Manual, Semi-Automatic and Automatic mode in different throwing capacities.",
      image: semi_automatic,
      link: "semi-automatic"
    },
    {
      name: "AUTO COLLECTOR / TIPPER / CLOSE TIPPER",
      desc: "Auto collector equipments can dump the garbage on the ground, as well as director into a collector and also in the refuse collector.",
      image: auto_collector,
      link: "auto-collector"
    },
    {
      name: "REFUSE COMPACTOR",
      desc: "Refuse compactors are heavy capacity of garbage loaders in its product range which is used for storing the large amount of waste as well as compact the same in a effective manner, also use for lifting or unloading the bins directly into its rear hopper.",
      image: refuse_compactor,
      link: "refuse-compactor"
    },
    {
      name: "DUMPER PLACER",
      desc: "Dumper placer is also known as skip loader which is used fo carrying the different size of garbage containers or bins from one place to another. These are being manufactured with high strength, reliability, superior quality & utilization for its end use.",
      image: dumper_placer,
      link: "dumper-placer"
    },
    {
      name: "HOOK-LOADER",
      desc: "Hook-Loader Facilitates Safe and Hygienic Collection, Storage and Large Volume of Garbage Transportation With Fast Operation.",
      image: hook_loader,
      link: "hook-loader"
    },
    {
      name: "PORTABLE COMPACTOR",
      desc: "Portable Compactors are supplied in roll-on/roll-off (hook lift) formats, to suit customer’s transport requirements and with integrated or external bin lifts. With compaction ratios of around 5:1 a portable compactor can be hold much more waste than a standard skip, meaning many fewer collections for the waste to be disposed.",
      image: portable_compactor,
      link: "portable-compactor"
    },
    {
      name: "ROAD & STREET SWEEPERS",
      desc: "Our range of products include Road Sweeping Machine such as Mechanical Sweeping Machine, Tractor Attach Road Sweeping Machine and Truck Mounted Road Sweeping Machine.",
      image: road_n_street_sweeper,
      link: "road-street-sweeper"
    },
    {
      name: "SUPER SUCKER",
      desc: "The Inventa Energy Pvt. Ltd. Super Sucker Machine is designed with the latest technology and Manufactured with strictest adherence to the highest.",
      image: super_sucker,
      link: "super-sucker"
    },
    {
      name: "SUCTION CUM JETTING",
      desc: "Suction cum Jetting is a Combination Machine, which combines High Pressure Sewer Jetting Operation (JetMan) as well as Suction Operation with Blowback System (GullyMan). This Machine can carry out both the operations of Jetting i.e cleaning of underground drain lines as well as Suction i.e sucking of sludge from the chambers.",
      image: suction_cum_jetting,
      link: "suction-cum-jetting"
    },
    {
      name: "SEWER SUCTION",
      desc: "Sewer Suction cum jetting machine is used for cleaning of sewer lines by sucking out the dirt from chamber into its tank as well as clear the blockage / choke of sewer lines by high capacity jetting throw of water.",
      image: sewer_suction,
      link: "sewer-suction"
    },
    {
      name: "NALA CLEANING",
      desc: "We are Manufacturing highly productive Nala Cleaning Machine that are used to providing the cleaning facility of open Nala & other waste yard. We are Manufactured with high Quality Materials, reliability, superior hydraulic system & utilization for its end use.",
      image: nala_cleaning,
      link: "nala-cleaning"
    },
    {
      name: "DESILTING",
      desc: "Desilting Machine is developed on a three-wheeler and on TATA ACE. Used for effective and efficient cleaning of silt from manholes and wet wells/sump house, our quality machines have helped us garner a rich clientele in the market.",
      image: desilting,
      link: "desilting"
    },
    {
      name: "SKY LIFT / HYDRAULICS ACCESS PLATFORM",
      desc: "Sky lift is an also known as automatic lifting machine for maintenance purposes in different sections such as municipalities works, Highways, Building Glass Cleaning & Constructions etc.",
      image: sky_lift,
      link: "sky-lift"
    },
    {
      name: "MOBILE TOILET BIO",
      desc: "Eco-Friendly Mobile Toilet Van. The offered toilet finds wide application use in the trade fair. Furthermore, the toilet van is manufactured using quality material and approved technologies.",
      image: mobile_toilet,
      link: "mobile-toilet"
    },
    {
      name: "PORTABLE TOILET / URINAL / MULTI STREET / BUNK HOUSE",
      desc: "We Are Manufacturing A Wide Range of Fully Furnished Mobile Portable Toilets & Bio Toilets as per DRDO Norms That are Used to Providing The Public Facilities At Mega Fairs, Public Festivals, Exhibitions & Municipalities Use Etc.",
      image: portable_toilet,
      link: "portable-toilet"
    },
    {
      name: "MULTI SEATER",
      desc: "Being a quality-centric company, we are engaged in offering a superior quality Mobile Toilet Van 10, 8, 6, 4 and 2 Seater. Our offered van is precisely manufactured using modern technology by our team of qualified professionals who have vast knowledge & experienced.",
      image: multi_seater_toilet,
      link: "multi-seater-toilet"
    },
    {
      name: "WATER TANKS",
      desc: "We are manufacturer & supplier of premium quality SS / MS Water Storage Tank. Latest production techniques, use of excellent raw material combined with innovative styling & designing has made this product reach the pinnacle where it is today.",
      image: water_tanker,
      link: "water-tanker"
    },
    {
      name: "FOGGING MACHINE",
      desc: "Leading Trader and Supplier of Fogging Machine such as Handy Fogging Machine, Vehicle Mounted Fogging Machine and Portable Fogging Machine",
      image: fogging_machine,
      link: "fogging-machine"
    },
    {
      name: "Dustbins",
      desc: "As one of the leading entity of this domain, we are engrossed in providing supreme quality Dust Bin. The offered bin finds wide application use in hotels, offices, restaurants, malls, parks and other similar places for keeping the environment neat and clean.",
      image: dustbin,
      link: "dustbin"
    },
  ]

  return (
    <Layout id="home">
      <section
        id="adoutus-sec-1"
        style={{ backgroundImage: `url(${green_city})` }}
      >
        <div className="about-sec1-content text-center">
          <p>Welcome to</p>
          <h4 className="animate-charcter">Inventa Energy</h4>
          <Link to="/#home-about" className="down-link">
            <BsChevronDown id="down-arrow" />
          </Link>
        </div>
      </section>

      <section id="home-about">
        <Container className="home-about-container" fluid="md">
          <Row className="about-us-row">
            <Col md={4} id="col1">
              <Image className="ml-2" src={who_we_are} alt="" fluid />
              {/* <img src={who_we_are} alt="" /> */}
            </Col>

            <Col md={8} id="col2">
              {/* <div id="icon">
            <FaAward size="60" color="var(--color-primary)" />
          </div> */}

              <div>
                <h2 id="home-headings">Who We Are?</h2>
                <hr id="home-hr" />
              </div>

              <p>
                We have carved a niche in the industry with our presence and
                experience of more than 3 years, which has lead to our range of
                unmatched quality. Our organization is ISO 9001-2008 CE
                Certified accredited, which speaks in volume about the nature
                and quality of our organization. The quality of our range is
                based on the following parameters:
              </p>

              <div>
                <ul>
                  <li>
                    <BsFillSquareFill className="li-icon" />
                    Global Design
                  </li>
                  <li>
                    <BsFillSquareFill className="li-icon" />
                    High Performance
                  </li>
                  <li>
                    <BsFillSquareFill className="li-icon" />
                    Unmatched Quality
                  </li>
                  <li>
                    <BsFillSquareFill className="li-icon" />
                    Client Satisfaction
                  </li>
                </ul>
              </div>

              <div className="know-more-btn2">
                <Link className="link" to="/about-us">
                  KNOW MORE
                  <BsArrowRight id="icon" size="20" />
                </Link>
              </div>

              <div className="know-more-btn">
                <Link className="link" to="/about-us">
                  KNOW MORE
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Gallery Section */}
      <section className="gallery-section">
        <Container fluid="md">
          <div>
            <h2 id="home-headings">Our Products</h2>
            <hr id="home-hr" />
          </div>

          <Carousel indicators={false}>
            {products.map(prod => (
              <Carousel.Item>
                <Row>
                <Col
                  md={6}
                  style={{
                    backgroundImage: `url(${prod.image})`,
                    backgroundPosition: "center center",
                    height: "360px",
                    backgroundSize: "cover",
                  }}
                >
                </Col>
                <Col
                  md={6}
                  className="our-prod-col2"
                  style={{
                    backgroundColor: "#ed3237",
                    color: "white",
                    padding: "20px",
                    paddingRight: "35px",
                  }}
                >
                  <h4 style={{textTransform: 'uppercase'}}>{prod.name}</h4>
                  <p>{prod.desc}</p>

                  <div className="know-more-btn2">
                    {/* TODO: Get Page Names */}
                    <Link className="link" to={prod.link}>
                      KNOW MORE
                      <BsArrowRight id="icon" size="20" />
                    </Link>
                  </div>
                </Col>
              </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
